@font-face {
    font-family: 'PTSans';
    src: url('../fonts/PTSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PTSans';
    src: url('../fonts/PTSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {

}

#root {
    min-height: 100vh;
}
* {
    box-sizing: border-box;
}

body {
    margin: 0 auto;
    font-size: 30px;
    font-family: 'PTSans', 'Verdana', 'sans-serif';
    background-image: url('../static/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.my-cell-style  {
    background-image: url('../static/cell.jpg');
    background-position: center;
    background-size: contain;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(4px);
    display: inline-block;
}

.header-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-score {
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 20px;
 margin: 20px;
}

.header-privacy {
display: flex;
align-items: center;
justify-content: center;
gap: 20px;
margin-left: 20px;
}
.header-privacy__link {
    color: white;
    text-decoration: none;

}
.my-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my-title h3 {
    font-weight: 700;
    color: rgba(31, 31, 31, 0.856);
    text-shadow: 1px 1px 5px rgba(187, 84, 0, 0.829);
}

@media (max-width: 768px) {
    .header-info {
        flex-direction: column;
    }
    .header-score {
        margin: 20px 20px 0 20px;
    }
    .header-privacy {
        margin-left: 0;
    }
}
@media (max-width: 530px) {
    .my-title h3 {
        font-size: 2rem !important;
    }
    .header-privacy__link {
      font-size: 1rem;
    }
    .header-privacy {
        margin: 0;
    }
}